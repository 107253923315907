<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-row class="mb-2">
          <b-col
            xs="12"
            md="10"
          />
          <b-col
            xs="12"
            md="2"
          >
            <b-button
              to="/marketing/ad_types/add"
              variant="primary"
              block
            ><FeatherIcon icon="PlusIcon" />
              Ekle</b-button>
          </b-col>
        </b-row>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
          >
            <template #cell(control)="data">
              <b-button
                :to="'/marketing/ad_types/edit/' + data.item.id"
                variant="outline-primary"
                size="sm"
              >
                Güncelle
              </b-button>
            </template>
          </b-table></b-card-text>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BCardText, BRow, BCol, BTable, BCardFooter, BPagination, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'title',
          label: 'Başlık',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '50px', textAlign: 'left', verticalAlign: 'middle' },
        },
      ],
      dataQuery: {
        select: [
          'com_marketing_ad_types.id AS id',
          'com_marketing_ad_types.title AS title',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      const data = this.$store.getters['marketingAdTypes/getMarketing_ad_types']
      return data
    },
    dataCount() {
      return this.$store.getters['marketingAdTypes/getMarketing_ad_typesCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('marketingAdTypes/marketing_ad_typesList', this.dataQuery)
    },
  },
}
</script>

<style scoped>

</style>
